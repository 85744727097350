import { AxiosResponse } from "axios";

import { microserviceApiRoutes } from "../../microservice-api.routes";
import { microserviceApiAxios } from "../../microservice-api.axios";
import {
  ITouchIDEnelServiceBodyReq,
  ITouchIDEnelServiceRes,
  ITouchIDInfoEnelServiceBodyReq,
  ITouchIDInfoEnelServiceRes,
} from "./touchID-service.interface";

export const touchIDEnelService = (data: ITouchIDEnelServiceBodyReq) => {
  const url = microserviceApiRoutes.v1.enel.touchID();
  return microserviceApiAxios
    .post<Error, AxiosResponse<ITouchIDEnelServiceRes>>(url, data)
    .then((res) => res.data);
};

export const getTouchIDInfoEnelService = (
  data: ITouchIDInfoEnelServiceBodyReq,
) => {
  const url = microserviceApiRoutes.v1.enel.infoTouchID();
  return microserviceApiAxios
    .post<Error, AxiosResponse<ITouchIDInfoEnelServiceRes>>(url, data)
    .then((res) => res.data);
};
