import React, { useEffect, useState } from "react";
import { When, Unless } from "react-if";
import QRCode from "react-qr-code";
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import { useCreateEnelContext } from "../../provider/createEnel.provider";
import {
  useTouchIDInfoService,
  useTouchIDService,
} from "../../../../../services/microservice/enel/touchID/use-touchID";
import { Skeleton } from "@mui/material";
import { useStepCreateEnelContext } from "../stepper.provider";
import ERRORS from "../../../components/errors";

interface ITouch {
  touchUrl: string;
  touchId: string;
}

const TouchStepComponent = () => {
  const { device, setTouchID } = useCreateEnelContext();
  const { setCurrentStep } = useStepCreateEnelContext();
  const { mutateAsync: getTouchID, isSuccess } = useTouchIDService();
  const { mutateAsync: getInfoTouchID, isLoading } = useTouchIDInfoService();
  const [touchInfo, setTouchInfo] = useState<ITouch>();
  const [copy, setCopy] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleGetTouchID = async () => {
    try {
      const res = await getTouchID({
        imei: device?.imei,
        sponsorID: "ENEL",
        sendEmail: false,
      });
      setTouchInfo(res.data);
      setTouchID(res.data.touchId);
    } catch (err) {
      console.log(err);
    }
  };
  const handlePreviusStep = () => {
    setCurrentStep(3);
  };
  const handleNextStep = () => {
    setCurrentStep(5);
  };
  const handleCopyUrl = () => {
    navigator.clipboard.writeText(touchInfo.touchUrl);
    setCopy(true);
  };
  const handleValidate = async () => {
    try {
      const res = await getInfoTouchID({
        sponsorID: "ENEL",
        touchID: touchInfo.touchId,
      });
      if (res.data.isValidTouch) {
        setValidate(true);
        setError("");
      } else {
        setError(ERRORS.ERROR_TOUCH);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        setError(ERRORS.ERROR_TOUCH);
      } else {
        setError(ERRORS.INTERNAL);
      }
    }
  };
  useEffect(() => {
    handleGetTouchID();
  }, []);
  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        4. <span style={{ color: "#461E7D" }}>Prueba</span> de dispositivo
      </h2>

      <div className="mb-10 w-full">
        <p className="text-xl font-normal ">
          Para continuar con el proceso se realizara una prueba del dispositivo
          con el cual activara la cobertura seleccionada, esta prueba esta
          disponible en el siguiente código QR
        </p>
        <When condition={!isSuccess}>
          <div className="flex w-full justify-center">
            <Skeleton variant="rectangular" width={150} height={150} />
          </div>
        </When>
        <Unless condition={!isSuccess}>
          <div className="flex w-full flex-col items-center justify-center">
            <QRCode value={touchInfo?.touchUrl} className="my-5 h-40 w-40" />
            <div className="mb-5 mt-4 flex w-full justify-between">
              <PrimaryButton
                title={"Generar nuevo código QR"}
                onClick={() => handleGetTouchID()}
                className=" rounded-2xl px-5 text-white"
                style={{ backgroundColor: "#461E7D" }}
              />
              <PrimaryButton
                title={copy ? "Copiado" : "Copiar Link"}
                onClick={() => handleCopyUrl()}
                className=" rounded-2xl px-5 text-white"
                style={{ backgroundColor: "#461E7D" }}
              />
            </div>
          </div>
        </Unless>
        <p className="text-xl font-normal ">
          Una vez finalizada la prueba, realiza la validación en el siguiente
          boton, con esto seguir con el proceso de activación de cobertura.
        </p>
        <div className="my-3 flex w-full justify-center">
          <PrimaryButton
            title={validate ? "Validado" : "Validar"}
            onClick={() => handleValidate()}
            className=" rounded-2xl px-5 text-white"
            style={{ backgroundColor: "#461E7D" }}
            loading={isLoading}
          />
        </div>
        {error && <p className="text-lg text-critical">{error}</p>}
      </div>
      <div className="mt-4 flex justify-between">
        <PrimaryButton
          title="Atras"
          className="rounded-2xl border px-5 text-textBlack"
          type="button"
          style={{ borderColor: "#461E7D" }}
          onClick={() => handlePreviusStep()}
        />
        <PrimaryButton
          title="Siguiente"
          className="rounded-2xl  px-5 text-white"
          style={{ backgroundColor: "#461E7D", opacity: validate ? 1 : 0.5 }}
          disabled={!validate}
          onClick={() => handleNextStep()}
        />
      </div>
    </section>
  );
};

export default TouchStepComponent;
