import React from "react";

import LogoAcinco from "../../../assets/images/logo_acinco_white.png";
import LogoAxa from "../../../assets/images/AXA_Logo_new.png";
import { ReactComponent as LogoCall } from "../../../assets/icons/call-calling.svg";

const Footer = () => {
  return (
    <section
      className="flex w-full justify-center p-0 2xl:h-56"
      style={{ backgroundColor: "#461E7D" }}
    >
      <div className="container w-full justify-center py-10 text-whiteSoft lg:py-10 xl:px-10 2xl:px-20">
        <div className="mx-10 grid pb-10 lg:mx-0 xl:grid-cols-4 2xl:grid-cols-4">
          <div className="col-span-2 flex items-center justify-center align-top ">
            Respaldado por{"  "}
            <img src={LogoAcinco} alt="logo_acinco" className="max-h-20" />
            <img src={LogoAxa} alt="logo_axa" className="max-h-16" />
          </div>
          <div className="xl:col-span-2 2xl:col-span-2">
            <div className="mb-2 flex">
              <LogoCall className="mt-3 w-10 " />
              <div className="ml-3">
                <p className="text-md font-medium">Necesita ayuda?</p>
                <p className="text-md font-medium">
                  (601) 4898599 - 01 8000 513 323
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full border-t pt-2 text-center">
          <p>
            ©2023 Acinco S.A.S. <div className="sm:hidden">&nbsp;</div>
            Todos los derechos reservados
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
