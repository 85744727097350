import React from "react";
import { useStepCreateEnelContext } from "./stepper.provider";
import LoginStepComponent from "./steps/login.step.component";
import PlanStepComponent from "./steps/plans.step.component";
import ImeiStepComponent from "./steps/imei.step.component";
import TouchStepComponent from "./steps/touch.step.component";
import CreateStepComponent from "./steps/create.step.component";

const StepperBody = () => {
  const { currentStep } = useStepCreateEnelContext();

  const firstStep = <LoginStepComponent />;
  const secondStep = <PlanStepComponent />;
  const thirdStep = <ImeiStepComponent />;
  const fourthStep = <TouchStepComponent />;
  const fiveStep = <CreateStepComponent />;

  return (
    <section className="mx-auto w-3/4 border-t pt-10 lg:w-2/4 ">
      <div className="mx-auto">
        {currentStep === 1 && firstStep}
        {currentStep === 2 && secondStep}
        {currentStep === 3 && thirdStep}
        {currentStep === 4 && fourthStep}
        {currentStep === 5 && fiveStep}
      </div>
    </section>
  );
};

export default StepperBody;
