import React, { ReactNode, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface IProps {
  question: string;
  answer: JSX.Element | ReactNode;
}

const CardDropdown = ({ question, answer }: IProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <div className="mx-5 my-10 rounded-md py-5 shadow-md lg:mx-0 lg:px-10">
      <div
        className="mx-5 flex justify-between lg:mr-10"
        onClick={() => setVisible((prevState) => !prevState)}
      >
        <h3 className="text-left text-base font-medium lg:text-2xl">
          {question}
        </h3>
        <button
          className={
            visible
              ? "rotate-180 transition-all ease-out"
              : "rotate-0 transition-all ease-out"
          }
        >
          <KeyboardArrowDownIcon />
        </button>
      </div>
      {visible && (
        <div className="transition-all duration-500 ease-out ">{answer}</div>
      )}
    </div>
  );
};

export default CardDropdown;
