import { AxiosResponse } from "axios";

import { microserviceApiRoutes } from "../../microservice-api.routes";
import { microserviceApiAxios } from "../../microservice-api.axios";
import { IGetInfoImeiReqBody, IGetInfoImeiRes } from "./imei-service.interface";

export const imeiInfoService = (data: IGetInfoImeiReqBody) => {
  const url = microserviceApiRoutes.v1.enel.imei();
  return microserviceApiAxios
    .post<Error, AxiosResponse<IGetInfoImeiRes>>(url, data)
    .then((res) => res.data);
};
