import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { When, Unless } from "react-if";

import { useStepCreateEnelContext } from "../stepper.provider";
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import { useCreateEnelContext } from "../../provider/createEnel.provider";
import { useGetMeEnelService } from "../../../../../services/microservice/enel/auth/use-auth";

const PlansStepComponent = () => {
  const [avalaiblePlans, setAvalaiblePlans] = useState<number[]>([]);
  const { setCurrentStep } = useStepCreateEnelContext();
  const { setUser } = useCreateEnelContext();
  const { data: UserData, isLoading } = useGetMeEnelService({ retry: false });
  useEffect(() => {
    if (UserData) {
      let arr = [];
      for (let i = 0; i < UserData?.data.policies_quota; i++) {
        arr.push(i);
      }
      setAvalaiblePlans(arr);
    }
  }, [UserData]);
  const handlePressButton = () => {
    try {
      setUser(UserData?.data);
      setCurrentStep(3);
    } catch (err) {
      console.log(err);
    }
  };
  const handlePreviusStep = () => {
    setCurrentStep(1);
  };
  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        2. <span style={{ color: "#461E7D" }}>Selecciona</span> tu cobertura
      </h2>
      <When condition={isLoading}>
        <div className="mb-10 w-full">
          <Skeleton variant="text" width={200} height={50} />
          <div className="grid grid-cols-2 gap-4">
            <Skeleton
              variant="rectangular"
              height={220}
              width={230}
              className="grid- rounded-xl"
            />
            <Skeleton
              variant="rectangular"
              height={220}
              width={230}
              className=" rounded-xl"
            />
            <Skeleton
              variant="rectangular"
              height={220}
              width={230}
              className=" rounded-xl"
            />
          </div>
        </div>
      </When>
      <Unless condition={isLoading}>
        <p className="my-5 text-left text-xl font-normal">
          Afiliado:{" "}
          <span className="font-bold">
            {UserData?.data.name} {UserData?.data.second_name}
          </span>
        </p>
        <p className="my-5 text-left text-xl font-normal">
          Plan: <span className="font-bold">{UserData?.data.plan_name}</span>
        </p>
        <div className="mb-10 w-full">
          <div className="grid grid-cols-2 gap-4">
            {UserData?.data.insured_devices.map((device) => (
              <button
                key={`${device.imei}_${device.policy_id}`}
                className="mx-2 h-52 w-full cursor-default rounded-xl border-x-0 bg-white shadow-md"
              >
                <p className="text-center text-xl font-bold">
                  {device.comercial_name}
                </p>
                <p className="text-md text-center font-normal">
                  IMEI: {device.imei}
                </p>
                <p className="text-center text-base text-primary">Asegurado</p>
              </button>
            ))}
            {}
            {avalaiblePlans.map((device) => (
              <button
                key={`plans_available_${device}`}
                className="mx-2 h-52 w-full  rounded-xl border-x-0 bg-white shadow-md"
                onClick={() => handlePressButton()}
              >
                <p className="text-center text-xl font-bold">
                  Proteger nuevo equipo
                </p>
                <p className="text-center text-base text-primary">
                  {UserData?.data.policies_quota} Disponibles
                </p>
              </button>
            ))}
          </div>
        </div>
      </Unless>
      <div className="mt-4 flex justify-between">
        <PrimaryButton
          title="Atras"
          className="rounded-2xl border px-5 text-textBlack"
          onClick={() => handlePreviusStep()}
          type="button"
          style={{ borderColor: "#461E7D" }}
        />
      </div>
    </section>
  );
};

export default PlansStepComponent;
