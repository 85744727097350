import React from "react";

const Banner = () => {
  return (
    <section
      className="w-full bg-whiteSoft"
      style={{
        backgroundImage: "url(/enel/banner_enel.jpg)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="container mx-auto">
        <div className="grid grid-cols-1 pt-48 lg:mx-auto lg:grid-cols-1">
          <div className="mb-28 ml-2 pt-10 lg:mb-48 lg:ml-24 lg:py-36">
            <p className="ml-10 mt-5 text-center text-4xl font-bold text-white sm:mr-0 sm:text-left">
              En 5 pasos activa la protección en línea de tus dispositivos
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
