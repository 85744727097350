import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { IUser, IDevice } from "./types/createEnel.types";

interface IProps {
  children: ReactNode;
}

type IContext = {
  user: IUser | null;
  device: IDevice | null;
  touchID: string | null;
  setUser: Dispatch<SetStateAction<IUser | null>>;
  setDevice: Dispatch<SetStateAction<IDevice | null>>;
  setTouchID: Dispatch<SetStateAction<string | null>>;
};

export const CreateEnelContext = React.createContext<IContext>({} as IContext);
export const useCreateEnelContext = () =>
  useContext<IContext>(CreateEnelContext);

const CreateEnelProvider = ({ children }: IProps) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [device, setDevice] = useState<IDevice | null>(null);
  const [touchID, setTouchID] = useState<string | null>(null);
  const context: IContext = useMemo(
    () => ({
      user,
      device,
      touchID,
      setTouchID,
      setUser,
      setDevice,
    }),
    [user, device, touchID],
  );

  return (
    <CreateEnelContext.Provider value={context}>
      {children}
    </CreateEnelContext.Provider>
  );
};

export default CreateEnelProvider;
