import React from "react";
import StepCreateEnelProvider from "./stepper/stepper.provider";
import StepperHeader from "./stepper/stepper.header";
import StepperBody from "./stepper/stepper.body";
import CreateEnelProvider from "./provider/createEnel.provider";

const CreateEnel = () => {
  return (
    <CreateEnelProvider>
      <StepCreateEnelProvider>
        <StepperHeader />
        <StepperBody />
      </StepCreateEnelProvider>
    </CreateEnelProvider>
  );
};

export default CreateEnel;
