import React, { InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  classNameContainer?: string;
  classNameInput?: string;
  classNameIcon?: string;
  icon?: React.ReactElement | React.ReactNode;
  error?: string;
  required?: boolean;
  loading?: boolean;
}

const Input = ({
  classNameContainer,
  classNameInput,
  icon,
  classNameIcon,
  error,
  required = false,
  loading = false,
  ...props
}: InputProps) => {
  return (
    <div className={`${classNameContainer ? classNameContainer : ""} relative`}>
      <div className="h-10">
        <p className="my-0 py-0 text-base">
          {props.placeholder}{" "}
          {required && (
            <span className="text-center text-3xl text-critical">*</span>
          )}
          {error && (
            <span className="my-0 py-0 text-base text-critical">{error}</span>
          )}
        </p>
      </div>
      <input
        className={`${classNameInput ? classNameInput : ""} rounded-lg bg-gray px-3 py-2  text-textBlack`}
        {...props}
      />
      {icon && (
        <div
          className={`${classNameIcon ? classNameIcon : ""} absolute right-0 top-0 h-full rounded-r-md`}
        >
          {icon}
        </div>
      )}
      {loading && (
        <div
          className="absolute right-0 top-12 z-50 mr-2 h-5 w-5 animate-spin rounded-full border-4 border-b-gray border-l-gray border-r-gray"
          style={{ borderTopColor: "#461E7D" }}
        />
      )}
    </div>
  );
};

export default Input;
