import React from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

import Logo from "../../../assets/images/samsung_care_plus.png";

const Navbar = () => {
  const { pathname } = useLocation();

  const handleUnderlineNav = (path: string): string => {
    if (path === pathname) {
      return "underline underline-offset-4";
    } else {
      return "";
    }
  };
  return (
    <nav className={`fixed z-40 w-full bg-white`}>
      <div className="max-w-screen flex  lg:justify-between">
        <div className="flex w-full items-center justify-between px-5 lg:justify-center lg:px-0 xl:w-2/6 2xl:w-2/6">
          <Link to="/samsung-care-plus/reclamaciones">
            <div className="-ml-7 w-40 pt-5 lg:ml-0 lg:w-56 lg:pt-2">
              <img alt="acinco_logo" src={Logo} className="img-fluid" />
            </div>
          </Link>
        </div>
        <div className="my-auto mr-10 pt-5 lg:pt-0">
          <ul>
            <li
              className={clsx([
                "my-5 mt-1 text-nowrap text-sm font-medium text-buttonBlack hover:underline hover:underline-offset-4 lg:my-0 lg:text-lg",
                handleUnderlineNav("/samsung-care-plus/faq"),
              ])}
            >
              <Link to="/samsung-care-plus/faq">Preguntas frecuentes</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
