import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../claimsSamsungCarePlus/components/footer";
import Navbar from "../../claimsSamsungCarePlus/components/navbar";
import FAQComponent from "./componentes/faqcomponent";

const FAQSamsungCarePlusPage = () => {
  return (
    <>
      <Helmet>
        <title>FAQ | Samsung Care +</title>
        <meta name="description" content="FAQ Samsung Care +" />
      </Helmet>
      <Navbar />
      <FAQComponent />
      <Footer />
    </>
  );
};

export default FAQSamsungCarePlusPage;
