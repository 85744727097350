import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";

type IContext = {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
};

interface IProps {
  children: ReactNode;
}

export const StepCreateEnelContext = React.createContext<IContext>(
  {} as IContext,
);
export const useStepCreateEnelContext = () =>
  useContext<IContext>(StepCreateEnelContext);

const StepCreateEnelProvider = ({ children }: IProps) => {
  const [currentStep, setCurrentStep] = useState<number>(1);

  const context: IContext = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
    }),
    [currentStep],
  );

  return (
    <StepCreateEnelContext.Provider value={context}>
      {children}
    </StepCreateEnelContext.Provider>
  );
};

export default StepCreateEnelProvider;
