import React from "react";
import { Helmet } from "react-helmet";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import Banner from "./components/banner";
import CreateEnel from "./create/createEnel";
const CreateEnelPage = () => {
  return (
    <>
      <Helmet>
        <title>Activar poliza | Enel</title>
        <meta
          name="description"
          content="¡Es muy simple! Con tan solo 5 sencillos pasos puedes activar tu poliza."
        />
      </Helmet>
      <Navbar />
      <Banner />
      <section className="flex w-full justify-center p-0">
        <div className="container w-full px-4 py-20 lg:px-20">
          <div className="rounded-xl py-5 shadow-xl lg:px-10">
            <CreateEnel />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CreateEnelPage;
