import { useMutation, QueryOptions } from "react-query";

import {
  touchIDEnelService,
  getTouchIDInfoEnelService,
} from "./touchID-service";
import {
  IGetTouchIDService,
  IGetTouchIDInfoService,
} from "./use-touchID.interface";

export const useTouchIDService = (config?: QueryOptions) => {
  return useMutation(
    (data: IGetTouchIDService) => touchIDEnelService(data).then((res) => res),
    config,
  );
};

export const useTouchIDInfoService = (config?: QueryOptions) => {
  return useMutation(
    (data: IGetTouchIDInfoService) =>
      getTouchIDInfoEnelService(data).then((res) => res),
    config,
  );
};
