import React from "react";
import {
  Group,
  AddModerator,
  PhonelinkLock,
  DeviceUnknown,
  PhonelinkSetup,
} from "@mui/icons-material";
import clsx from "clsx";

import { useStepCreateEnelContext } from "./stepper.provider";

const StepperHeader = () => {
  const { currentStep } = useStepCreateEnelContext();

  const onCurrentStep = (step: number): string => {
    if (currentStep < step) {
      return "opacity-50";
    } else {
      return "";
    }
  };

  const steps = [
    {
      image: (
        <Group
          sx={{ fontSize: 60, color: currentStep >= 1 ? "#461E7D" : "#000000" }}
        />
      ),
      stepNumber: 1,
      title: "",
      titleBold: "Identifícate",
    },
    {
      image: (
        <AddModerator
          sx={{ fontSize: 60, color: currentStep >= 2 ? "#461E7D" : "#000000" }}
        />
      ),
      stepNumber: 2,
      title: "tu cobertura",
      titleBold: "Selecciona",
    },
    {
      image: (
        <DeviceUnknown
          sx={{ fontSize: 60, color: currentStep >= 3 ? "#461E7D" : "#000000" }}
        />
      ),
      stepNumber: 3,
      title: "tu dispositivo",
      titleBold: "Verifica",
    },
    {
      image: (
        <PhonelinkSetup
          sx={{ fontSize: 60, color: currentStep >= 4 ? "#461E7D" : "#000000" }}
        />
      ),
      stepNumber: 4,
      title: "de dispositivo",
      titleBold: "Prueba",
    },
    {
      image: (
        <PhonelinkLock
          sx={{ fontSize: 60, color: currentStep >= 5 ? "#461E7D" : "#000000" }}
        />
      ),
      stepNumber: 5,
      title: "dispositivo",
      titleBold: "Proteger",
    },
  ];

  return (
    <section className="w-full p-2 lg:p-10">
      <div className="lg:px-5">
        <p className="text-center lg:my-3">
          Aca puedes realizar la activación de la cobertura
        </p>
        <div className=" hidden lg:my-5 lg:grid lg:grid-cols-5 lg:gap-4 ">
          {steps.map(({ image, title, titleBold, stepNumber }) => (
            <div
              key={stepNumber}
              className={clsx([
                "my-10 flex flex-col items-center align-middle",
                onCurrentStep(stepNumber),
              ])}
            >
              {image}
              <h3 className="mt-2 text-center text-xl font-bold">
                {stepNumber}.{" "}
                <span style={{ color: "#461E7D" }}>{titleBold}</span> {title}
              </h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StepperHeader;
