import { ICredentialsUser } from "../services/microservice/types/authorization";

function getCredentials(): ICredentialsUser | undefined {
  const credentials =
    localStorage.getItem("credentialsUser") ||
    sessionStorage.getItem("credentialsUser");
  if (!credentials) return undefined;
  return JSON.parse(credentials);
}

const setCredentials = (credentials: ICredentialsUser, remember: boolean) => {
  const storage = remember ? localStorage : sessionStorage;
  storage.setItem("credentialsUser", JSON.stringify(credentials));
};

const clearCredentials = () => {
  localStorage.removeItem("credentialsUser");
  sessionStorage.removeItem("credentialsUser");
};

export const useLoginUser = () => {
  const credentials = getCredentials();
  const login = setCredentials;
  const logout = clearCredentials;
  const isLogin = Boolean(credentials?.type && credentials?.token);
  return { isLogin, credentials, login, logout };
};
