import { useMutation, QueryOptions } from "react-query";

import { createEnelService } from "./create-service";
import { ICreateTouchIDBody } from "./use-create.interface";

export const useCreateEnelService = (config?: QueryOptions) => {
  return useMutation(
    (data: ICreateTouchIDBody) => createEnelService(data).then((res) => res),
    config,
  );
};
