import { AxiosResponse } from "axios";

import { microserviceApiRoutes } from "../../microservice-api.routes";
import { microserviceApiAxios } from "../../microservice-api.axios";
import { ICreateTouchIDBodyReq } from "./create-service.interface";

export const createEnelService = (data: ICreateTouchIDBodyReq) => {
  const url = microserviceApiRoutes.v1.enel.create();
  return microserviceApiAxios
    .post<Error, AxiosResponse<{}>>(url, data)
    .then((res) => res.data);
};
