const ERRORS = {
  UNAUTHORIZED:
    "Credenciales incorrectas (correo electrónico o cédula), por favor verifícalas e intenta nuevamente. Si el problema continúa comunícate con soporte técnico.",
  INTERNAL:
    "Ocurrió un error inesperado, por favor intenta nuevamente. Si el problema continúa comunícate con soporte técnico.",
  ERROR_TOUCH:
    "No ha sido posible validar la prueba. Por favor, inténtalo nuevamente.",
  POLICY_CREATED:
    "Este dispositivo ya se encuentra protegido, por favor valida que has ingresado el IMEI correcto.",
  ERROR_DEVICE:
    "No podemos encontrar tu dispositivo, o el modelo del equipo no se encuentra incluido en el producto, por favor valida el IMEI. Si el problema persiste comunícate con soporte técnico.",
  PLAN_INVALID:
    "Este usuario no cuenta con un plan válido, por favor valida tus datos. Si el problema continúa comunícate con soporte técnico. ",
};

export default ERRORS;
