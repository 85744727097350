import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import { useStepCreateEnelContext } from "../stepper.provider";
import { useCreateEnelContext } from "../../provider/createEnel.provider";
import Input from "../../../../../components/inputs/Input";
import { useModalContext } from "../../../../../providers/modal/modal.provider";
import ModalHeader from "../../components/ModalHeader";
import ModalBody from "../../../../../components/modalv2/components/ModalBody";
import { useLoginUser } from "../../../../../hooks/useLoginUser";
import { useCreateEnelService } from "../../../../../services/microservice/enel/create/use-create";
import ERRORS from "../../../components/errors";

const schemaCreate = yup
  .object({
    line: yup
      .string()
      .matches(/^\d+$/, { message: " Requerido" })
      .min(10, " El número debe tener 10 digítos")
      .max(10, " El número debe tener 10 digítos")
      .required(" Requerido"),
  })
  .required();

const CreateStepComponent = () => {
  const { user, device, touchID, setDevice, setUser } = useCreateEnelContext();
  const { setCurrentStep } = useStepCreateEnelContext();
  const { setShow } = useModalContext();
  const { logout } = useLoginUser();
  const { mutateAsync: create, isLoading } = useCreateEnelService();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schemaCreate),
  });

  const handlePreviusStep = () => {
    setCurrentStep(4);
  };

  const handleMessageError = (err): string => {
    if (err === "El IMEI ya cuenta con una póliza para este sponsor") {
      return ERRORS.POLICY_CREATED;
    } else {
      return ERRORS.INTERNAL;
    }
  };
  const onSubmit = async (values) => {
    try {
      const res = await create({
        line: values.line,
        sponsorID: "ENEL",
        imei: device?.imei,
        tac: device?.imei.slice(0, 8),
        deviceName: device?.key,
        touchID: touchID,
      });
      if (res) {
        setShow((prevState) => ({
          ...prevState,
          visible: true,
          header: <ModalHeader success={true} />,
          content: (
            <ModalBody
              title="Proceso exitoso"
              message={`Tu activación fue creada exitosamente`}
            />
          ),
        }));
        setCurrentStep(1);
        setDevice(null);
        setUser(null);
        logout();
      }
    } catch (error) {
      setShow((prevState) => ({
        ...prevState,
        visible: true,
        header: <ModalHeader success={false} />,
        content: (
          <ModalBody
            title="Ocurrio un error"
            message={handleMessageError(error?.response?.data.error.message)}
          />
        ),
      }));
    }

    console.log("submit");
  };
  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        5. <span style={{ color: "#461E7D" }}>Proteger</span> dispositivo
      </h2>
      <div>
        <table className="text-gray-700 table w-full">
          <thead></thead>
          <tbody>
            <tr>
              <td className="border-t-0">
                <h6 className="mb-4 font-medium">Datos personales</h6>
              </td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td
                className="border-b-0 border-t-0 pb-3 pt-1 "
                style={{ width: "10px" }}
              >
                Nombre(s):
              </td>
              <td
                className="border-b-0 border-t-0   pb-3 pt-1 text-end"
                style={{ width: "10px" }}
              >
                {user?.name}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Apellido(s):</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {user?.second_name}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Email:</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {user?.email}
              </td>
            </tr>
            <tr>
              <td className="border-t-0">
                <h6 className="mb-4 mt-4 font-medium">Datos del dispositivo</h6>
              </td>
              <td className="border-t-0">&nbsp;</td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">
                Modelo del dispositivo:
              </td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {device?.key}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Fabricante:</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {device?.brand}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">IMEI:</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {device?.imei}
              </td>
            </tr>
            <tr>
              <td className="border-t-0">
                <h6 className="mb-4 mt-4 font-medium">Datos del plan</h6>
              </td>
              <td className="border-t-0">&nbsp;</td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Nombre:</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {user?.plan_name}
              </td>
            </tr>
          </tbody>
        </table>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="line"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                classNameContainer="w-full mb-7"
                classNameInput="w-full"
                placeholder="Número de celular"
                error={errors.line?.message}
                required
              />
            )}
          />
          <div className="mt-4 flex justify-between">
            <PrimaryButton
              title="Atras"
              className="rounded-2xl border px-5 text-textBlack"
              type="button"
              style={{ borderColor: "#461E7D" }}
              onClick={() => handlePreviusStep()}
            />
            <PrimaryButton
              type="submit"
              title="Proteger"
              className="rounded-2xl  px-5 text-white"
              style={{ backgroundColor: "#461E7D" }}
              loading={isLoading}
            />
          </div>
        </form>
      </div>
    </section>
  );
};

export default CreateStepComponent;
