import React, { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";

import Input from "../../../../../components/inputs/Input";
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import { useStepCreateEnelContext } from "../stepper.provider";
import { useLoginUser } from "../../../../../hooks/useLoginUser";
import { useLoginEnelService } from "../../../../../services/microservice/enel/auth/use-auth";
import ERROR from "../../../components/errors";

type IFormInput = {
  username: string;
  password: string;
  sponsorID: string;
  confirm: boolean;
};

const LabelCheckbox = styled.label`
  > input[type="checkbox"]:checked + i {
    background: "#000000" !important;
  }
`;

const schemaLogin = yup
  .object({
    username: yup
      .string()
      .matches(/^\d+$/, { message: " Requerido" })
      .required(" Requerido"),
    password: yup.string().email().required(" Requerido"),
    confirm: yup.boolean().oneOf([true], " Requerido"),
  })
  .required();

const LoginStepComponent = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(schemaLogin),
  });
  const { setCurrentStep } = useStepCreateEnelContext();
  const { login } = useLoginUser();
  const { mutateAsync: loginEnel, isLoading } = useLoginEnelService();
  const [error, setError] = useState<string>("");

  const onSubmit: SubmitHandler<IFormInput> = async (val) => {
    try {
      const res = await loginEnel({
        username: val.username,
        password: val.password,
        sponsorID: "ENEL",
      });
      login(res.data, false);
      setCurrentStep(2);
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 404) {
        setError(ERROR.UNAUTHORIZED);
      } else {
        setError(ERROR.INTERNAL);
      }
    }
  };
  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        1. <span style={{ color: "#461E7D" }}>Identifícate</span>
      </h2>
      {error && <p className="text-md text-critical">{error}</p>}
      <div className="mb-10 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                classNameContainer="w-full mb-7"
                classNameInput="w-full"
                placeholder="Número de identificación"
                error={errors.username?.message}
                required
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                classNameContainer="w-full my-2"
                classNameInput="w-full"
                placeholder="Email"
                error={errors.password?.message}
                required
              />
            )}
          />
          <div className="mt-4">
            <LabelCheckbox className="w-full text-base">
              <input
                {...register("confirm", {
                  required: {
                    value: true,
                    message: "* Requerido",
                  },
                })}
                type="checkbox"
              />
              <i></i> Acepta{" "}
              <a
                href="https://files-statics-protegeme.s3.amazonaws.com/ENEL/Politica-tratamiento-de-datos-2024.pdf"
                target="_blank"
                rel="noreferrer"
                style={{ whiteSpace: "nowrap", color: "black" }}
              >
                política de tratamiento de datos.{" "}
                {errors && (
                  <span className="mb-2 mt-1 py-0 text-base text-critical">
                    <span className="text-center text-3xl text-critical">
                      *
                    </span>{" "}
                    {errors.confirm?.message}
                  </span>
                )}
              </a>
            </LabelCheckbox>
          </div>
          <PrimaryButton
            title="INICIAR"
            className={
              "mt-5 rounded-3xl  px-5 py-1 text-sm font-normal text-whiteSoft"
            }
            loading={isLoading}
            style={{ backgroundColor: "#461E7D" }}
          />
        </form>
      </div>
    </section>
  );
};

export default LoginStepComponent;
