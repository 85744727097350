import React, { ReactNode } from "react";
import CardDropdown from "./cardDropdown";

interface IFaq {
  question: string;
  answer: JSX.Element | ReactNode;
}

const faq: IFaq[] = [
  {
    question: "¿Hay una tarifa de servicio/deducible?",
    answer: (
      <>
        <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
          Reparaciones por daños accidentales (Incluyendo falla mecanica o
          eléctrica) o por hurto: Se aplicará un deducible del 30%.
        </p>
        <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
          Reparaciones por garantía extendida: No se aplicará ningún deducible.
        </p>
      </>
    ),
  },
  {
    question: "¿Cuántas reclamaciones puedo presentar?",
    answer: (
      <>
        <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
          Por daños accidentales (Incluyendo falla mecanica o eléctrica) o
          hurto: Hasta 2 reclamaciones por vigencia.
        </p>
        <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
          Por garantía extendida, según la duración de tu plan de garantía
          extendida:
        </p>
        <ul className="ml-10 list-disc">
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Si tu plan cubre 1 año adicional, puedes hacer hasta dos (2)
              reparaciones por el mismo daño entre el mes 13 y el mes 24. Si
              realizadas las dos (2) reparaciones dentro del término de
              cubrimiento de la garantía extendida, el daño persiste, se
              procederá a reemplazar la unidad, previo diagnóstico del servicio
              técnico.
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Si tu plan cubre 2 años adicionales, puedes hacer hasta dos (2)
              reclamaciones por el mismo daño entre el mes 13 y el mes 24, y
              hasta dos (2) reclamaciones por el mismo daño entre el mes 25 y el
              mes 36. Si realizadas las dos (2) reparaciones dentro del término
              de cubrimiento de la garantía extendida, el daño persiste, se
              procederá a reemplazar la unidad, previo diagnóstico del servicio
              técnico.{" "}
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    question: "¿Qué documentos o archivos debo presentar en mi reclamación?",
    answer: (
      <>
        <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
          Hurto:
        </p>
        <ul className="ml-10 list-disc">
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Factura de compra del equipo.
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Fotocopia de la cédula de ciudadanía.
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Denuncio ante la Fiscalía (Hurto calificado) o declaración
              juramentada ante notaría (Hurto simple).
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Reporte del hurto registrado en el operador móvil.
            </p>
          </li>
        </ul>
        <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
          Daño Total Accidental:
        </p>
        <ul className="ml-10 list-disc">
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Fotocopia de la cédula de ciudadanía.
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Fotografías del equipo (frontal, posterior y lateral).
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Fotografía del IMEI (usando *#06# o de la ranura de la SIM).
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Captura de pantalla de desvinculación de cuotas de seguridad.
            </p>
          </li>
        </ul>
        <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
          Fractura de Pantalla o Daño Parcial:
        </p>
        <ul className="ml-10 list-disc">
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Fotocopia de la cédula de ciudadanía.
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Fotografías del equipo (frontal, posterior y lateral).
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Fotografía del IMEI (usando *#06# o de la ranura de la SIM).
            </p>
          </li>
        </ul>
        <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
          Garantía Extendida:
        </p>
        <ul className="ml-10 list-disc">
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Factura de compra del equipo.
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Fotocopia de la cédula de ciudadanía.
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Fotografías del equipo (frontal, posterior y lateral).
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Fotografía del IMEI (usando *#06# o de la ranura de la SIM).
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    question: "¿Cuánto tiempo tengo para presentar un reclamo?",
    answer: (
      <>
        <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
          Tienes 3 días desde que conociste o debiste conocer del daño o avería
          para presentar tu aviso de siniestro.
        </p>
        <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
          Por otro lado, la prescripción de la acción para generar el reclamo
          será de dos años y empezará a correr desde el momento en que hayas
          tenido o debido tener conocimiento del hecho que da base a la acción.
        </p>
      </>
    ),
  },
  {
    question: "¿Cuál es la forma más rápida de presentar un reclamo?",
    answer: (
      <>
        <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
          A través de la página:{" "}
          <a href="https://www.acinco.com.co/" target="_blank">
            www.aseguratucelular.com
          </a>
        </p>
        <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
          También puedes comunicarte a través de las líneas de atención:
        </p>
        <ul className="ml-10 list-disc">
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Bogotá D.C.: (601) 4898599
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              Línea nacional: 01-8000513323
            </p>
          </li>
          <li>
            <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
              WhatsApp: +57 1 5142355
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    question:
      "¿Cuál es la importancia de proporcionar un correo electrónico válido durante el proceso de reclamo?",
    answer: (
      <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
        Necesitamos confirmar tu identidad y asegurarnos de que solo tú puedas
        hacer una reclamación
      </p>
    ),
  },
  {
    question: "¿Qué pasa si no puedo encontrar el IMEI de mi dispositivo?",
    answer: (
      <p className="mx-5 mt-5 text-justify text-sm font-normal text-buttonBlack lg:text-lg">
        Busca el número IMEI de 15 dígitos debajo de la batería, en el empaque
        original o en el recibo de compra. Si el dispositivo funciona, marca
        *#06# para mostrarlo. También puedes encontrarlo en la configuración del
        dispositivo, dependiendo del fabricante.
      </p>
    ),
  },
];

const FAQComponent = () => {
  return (
    <section className="flex w-full justify-center p-0 pt-10 lg:pt-20">
      <div className="container w-full px-4 py-20 lg:px-20">
        <div className="rounded-xl py-5 shadow-xl lg:px-10">
          <h2 className="ml-10 text-xl font-bold text-buttonBlack lg:text-3xl">
            Preguntas frecuentes
          </h2>
          {faq.map((props) => (
            <CardDropdown
              key={`faq_${props.question}`}
              question={props.question}
              answer={props.answer}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQComponent;
