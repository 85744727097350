import { useMutation, QueryOptions } from "react-query";

import { imeiInfoService } from "./imei-service";
import { IGetInfoImeiService } from "./use-imei.interface";

export const useImeiInfoService = (config?: QueryOptions) => {
  return useMutation(
    (data: IGetInfoImeiService) => imeiInfoService(data).then((res) => res),
    config,
  );
};
